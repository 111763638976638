@import "common/styles/preload.scss";

.page {

  .headerSection {
    position: relative;

    .navi {
      position: absolute;
      width: 100%;
      z-index: $hy-z-low;
    }
  }
}