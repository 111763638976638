@import "common/styles/preload.scss";
.section {
  height: 680px;

  .bg {
    position: relative;
    margin: 0 auto;
    width: 1920px;
    height: 680px;
    left: 50%;
    transform: translateX(-960px);

    & > img {
      position: absolute;
      width: 1920px;
      height: 680px;
      object-fit: cover;
      object-position: center top;
    }
  }

  .content {
    position: relative;
    margin: 0 auto;
    z-index: $hy-z-low;
    width: $hy-content-width;

    .container {

      margin-top: 55px;

      img {
        margin-right: 28px;
        width: 271px;
        height: 116px;
      }
    }
  }
}