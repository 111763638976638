@import "common/styles/preload.scss";
.section {
  height: 680px;

  .bg {
    position: relative;
    margin: 0 auto;
    width: 1920px;
    height: 680px;
    left: 50%;
    transform: translateX(-960px);

    & > img {
      position: absolute;
      width: 1920px;
      height: 680px;
      object-fit: cover;
      object-position: center top;
    }
  }

  .content {
    position: relative;
    margin: 0 auto;
    z-index: $hy-z-low;
    width: $hy-content-width;

    .platform {
      width: 312px;
      height: 190px;
      background-color: white;
      border-radius: 4px;
      cursor: pointer;

      & > img {
        flex-shrink: 0;
        width: 132px;
        height: 190px;
      }

      .types {
        flex-grow: 1;
        margin-left: -10px;

        .item {

          &:not(:last-of-type) {
            margin-bottom: 20px;
          }

          &:hover {
            .text {
              color: $hy-color-primary;
            }
          }

          .dot {
            margin-right: 4px;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background-color: #bababa;
          }

          .text {
            font-size: $hy-font-size-normal;
          }
        }
      }
    }

    .right {
      margin-left: 70px;
      padding-top: 16px;
      width: 512px;

      img.logo {
        width: 60px;
        height: 68px;
      }

      .desc {
        margin-top: 22px;
        font-size: $hy-font-size-normal;
        color: white;
        line-height: 31px;
      }

      .option {
        width: 100%;
        height: 58px;
        border-radius: $hy-radius-button;
        background-color: white;

        .optionContent {
          flex-grow: 1;

          .icon {
            margin-right: 6px;
            width: 17px;
          }

          .notice {
            font-size: $hy-font-size-normal;
          }
        }

        .button {
          flex-shrink: 0;
          width: 170px;
          height: 100%;
          border-radius: $hy-radius-button;
          background-color: $hy-color-primary;
          font-size: $hy-font-size-normal;
          font-weight: bold;
          color: white;
          cursor: pointer;
        }
      }
    }
  }
}
