@import "common/styles/preload.scss";
.section {
  padding-bottom: 80px;
  background-color: white;

  .content {
    margin: 60px auto 0 auto;
    width: $hy-content-width;
    height: 613px;

    .left {
      flex-shrink: 0;
      padding-top: 40px;
      padding-left: 24px;
      width: 268px;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      .leftTitle {
        font-size: $hy-font-size-title2;
        line-height: $hy-font-size-title2;
        font-weight: bold;
        color: white;
      }

      .sub {
        margin-top: 20px;
        font-size: $hy-font-size-normal;
        color: rgba(255, 255, 255, 0.8);
      }

      .line {
        margin-top: 18px;
        margin-bottom: 20px;
        width: 34px;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.5);
      }

      .item {
        margin-top: 60px;
        margin-right: 33px;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.35);

        .itemNum {
          font-size: $hy-font-size-title1;
          font-weight: bold;
          color: white;
        }

        .itemName {
          margin-top: 14px;
          font-size: $hy-font-size-small;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }

    .right {
      flex-grow: 1;
      padding: 40px 18px 28px 30px;
      background-color: #F2F7FF;

      .rightTitle {
        font-size: $hy-font-size-title1;
        font-weight: bold;
      }

      .titleIcon {
        margin-left: 15px;
        width: 31px;
        height: 31px;
        cursor: pointer;
      }

      .advs {

        margin-top: 35px;
        padding-left: 15px;
        padding-right: 35px;
        height: 136px;
        background-color: white;

        .advItem {

          flex-grow: 1;
          flex-basis: 0;

          >img {
            margin-right: 8px;
            width: 94px;
            height: 94px;
          }

          .text1 {
            font-size: $hy-font-size-title3;
            font-weight: bold;
          }

          .text2 {
            margin-top: 15px;
            font-size: $hy-font-size-small;
            color: $hy-color-text-2;
          }
        }
        
        .seperator {
          width: 1px;
          height: 40px;
          background-color: #CFDEF4;
        }
      }

      .questions {

        margin-top: 34px;

        .questionItem {
          width: 310px;
          height: 306px;
          border-radius: $hy-radius-container;
          overflow: hidden;
          background-color: white;
          cursor: pointer;

          >img {
            flex-shrink: 0;
            width: 310px;
            height: 156px;
          }

          .questionContent {

            flex-grow: 1;

            .questionLeft {

              width: 110px;

              .questionIcon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 46px;
                height: 46px;
                >img {
                  object-fit: contain;
                }
              }
    
              .questionName {
                margin-top: 16px;
                font-size: $hy-font-size-normal;
              }
            }
  
            .questionLine {
              margin-right: 35px;
              width: 1px;
              height: 106px;
              background-color: #DDDDDD;
            }
  
            .questionDesc{
              
              font-size: $hy-font-size-small;
              color: $hy-color-text-2;

              &:not(:first-of-type) {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}
