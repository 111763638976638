@import "common/styles/preload.scss";
.footer {
  padding-top: 70px;
  padding-bottom: 20px;
  background-color: #182439;

  .top {
    height: 280px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .topContent {
      margin: 0 auto;
      width: $hy-content-width;
  
      .left {
        .logo {
          margin-bottom: 44px;
          width: 122px;
          height: 50px;
          @include hy-bg-img("/common/page-footer/logo.png");
        }
  
        .leftItem {
          display: flex;
  
          &:not(:last-of-type) {
            margin-bottom: 26px;
          }
  
          > img {
            margin-right: 12px;
            width: 15px;
            object-fit: contain;
          }
  
          img + div {
            font-size: $hy-font-size-small;
            color: $hy-color-text-3;
          }
        }
      }
  
      .mid {
  
        margin-top: 20px;
  
        .row {
          width: 140px;
  
          .columnTitle {
            font-size: $hy-font-size-normal;
            font-weight: bold;
            color: white;
          }
  
          .columnItem {
            display: block;
            margin-top: 24px;
            font-size: $hy-font-size-small;
            color: $hy-color-text-3;
            cursor: pointer;
          }
        }
      }
  
      .right {
  
        margin-top: 20px;
  
        .rightItem {
          &:not(:last-of-type) {
            margin-right: 30px;
          }
  
          > img {
            width: 160px;
            height: 160px;
          }
  
          img + div {
            margin-top: 22px;
            font-size: $hy-font-size-small;
            color: $hy-color-text-3;
          }
        }
      }
    }
  }

  .bottom {

    >div {
      margin-top: 10px;
      font-size: $hy-font-size-small;
      color: $hy-color-text-3;
    }

    .link {
      margin: 0 10px;
      width: 100px;
      height: 30px;
      cursor: pointer;
    }

    .linkContainer {

      margin-top: 20px;
      max-width: 1280px;

      .link {
        margin: 10px;
        font-size: $hy-font-size-small;
        color: $hy-color-text-3;
        cursor: pointer;
      }
    }
  }
}
