@import "common/styles/preload.scss";
.section {
  padding-bottom: 80px;
  background-color: #e9f2fb;

  .content {
    margin: 0 auto;
    width: $hy-content-width;

    .container {
      margin-top: 60px;

      .left {
        padding-top: 30px;
        padding-left: 20px;
        padding-right: 20px;
        width: 264px;
        height: 473px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-top-left-radius: $hy-radius-container;
        border-bottom-left-radius: $hy-radius-container;

        .title {
          font-size: $hy-font-size-title2;
          line-height: $hy-font-size-title2;
          font-weight: bold;
          color: white;
        }

        .titleDesc {
          margin-top: 11px;
          font-size: $hy-font-size-mini;
          color: rgba(255, 255, 255, 0.46);
          text-transform: uppercase;
          border-bottom: 1px solid;
          border-color: rgba(255, 255, 255, 0.46);;
        }

        .item {
          margin-top: 26px;

          .dot {
            margin-top: 10px;
            margin-right: 11px;
            width: 14px;
            height: 14px;
          }

          .icon {
            width: 42px;
            height: 42px;
          }

          .itemTitle {

            font-size: $hy-font-size-title3;
            font-weight: bold;
            color: white;
          }

          .itemDesc {
            margin-top: 6px;
            font-size: $hy-font-size-small;
            color: rgba(255, 255, 255, 0.7);
            line-height: 28px;
          }
        }

        .button {
          margin-left: 22px;
          margin-top: 20px;
          width: 156px;
          height: 40px;
          border: 1px solid rgba(255, 255, 255, 0.35);
          border-radius: $hy-radius-button;
          cursor: pointer;
          font-size: $hy-font-size-normal;
          color: white;
          line-height: 40px;
          text-align: center;
        }
      }

      :global {
        .ant-carousel {
          width: calc($hy-content-width - 264px);
          height: 100%;

          .slick-dots {
            bottom: -20px;
            left: -260px;
            li {
              width: 60px;
              height: 6px;
              box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    
              &.slick-active {
                background-color: $hy-color-primary;
                button {
                  background-color: $hy-color-primary;
                }
              }
            }
          }
        }
      }

      .right {
        width: 100%;
        height: 100%;

        .gridContainer {

          width: 100%;
          height: 100%;

          .grid {
            display: grid;
            height: 473px;
            background-color: white;
            grid-template-columns: repeat(5, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;

            .person {
              position: relative;
              width: 100%;
              height: 100%;
              border: 1px solid #e9f2fb;
              transition: all 0.3s ease-in-out;
              background-position: center bottom;
              background-repeat: no-repeat;
              background-size: cover;
              background-color: white;

              .bottom {
                position: absolute;
                opacity: 0;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 0;
                background-color: $hy-color-primary;
                transition: all 0.3s ease-in-out;

                .personName {
                  margin-right: 13px;
                  font-size: $hy-font-size-normal;
                  font-weight: bold;
                  color: white;
                }

                .personJob {
                  font-size: $hy-font-size-small;
                  color: rgba(255, 255, 255, 0.6);
                }

                .contactButton {
                  margin-top: 16px;
                  width: 153px;
                  height: 28px;
                  background: white;
                  border-radius: $hy-radius-button;
                  font-size: $hy-font-size-mini;
                  color: $hy-color-primary;
                  text-align: center;
                  line-height: 28px;
                  cursor: pointer;
                }
              }
            }

            .person:hover {
              box-shadow: $hy-shadow-container;
              background-color: lighten($hy-color-primary, 30%);

              .bottom {
                opacity: 1;
                height: 80px;
              }
            }
          }
        }
      }
    }
  }
}
