@import "common/styles/preload.scss";
.section {
  height: 755px;

  .bg {
    position: relative;
    margin: 0 auto;
    width: 1920px;
    height: 755px;
    left: 50%;
    transform: translateX(-960px);

    & > img {
      position: absolute;
      width: 1920px;
      height: 755px;
      object-fit: cover;
      object-position: center;
    }

    .content {
      position: relative;
      margin: 0 auto;
      z-index: $hy-z-low;
      width: $hy-content-width;

      .container {
        margin-top: 70px;
        height: 480px;

        .left {
          width: 265px;
          height: 100%;

          .header {
            padding-top: 39px;
            padding-left: 28px;
            width: 265px;
            height: 146px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            &Title {
              font-size: $hy-font-size-title3;
              font-weight: bold;
              color: white;
            }

            &Desc {
              margin-top: 16px;
              font-size: 36px;
              font-weight: bold;
              color: white;

              span {
                margin-left: 9px;
                display: inline-block;
                font-size: 14px;
                color: white;
              }
            }
          }

          .menu {
            position: relative;
            padding-top: 30px;
            padding-left: 30px;
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            > img {
              width: 36px;
              height: 36px;
              object-fit: contain;
            }

            img + div {
              margin-top: 20px;
              font-size: $hy-font-size-title3;
              color: white;
            }

            .more {
              position: absolute;
              left: 30px;
              bottom: 20px;
              width: 80px;
              height: 30px;
              border: 1px solid white;
              color: white;
              line-height: 30px;
              text-align: center;
              cursor: pointer;
              transition: all 0.3s ease-in-out;
            }

            .more:hover {
              text-shadow: 0 0 6px white;
            }
          }

          .select {
            flex-grow: 2;
            background-color: $hy-color-primary;

            .more {
              opacity: 1;
            }
          }

          .normal {
            flex-grow: 1;
            background-color: darken($hy-color-primary, $amount: 20%);

            .more {
              opacity: 0;
            }
          }

          .selectBar {
            position: absolute;
            top: 0;
            right: 0;
            width: 7px;
            height: 100%;
            background-color: lighten($hy-color-primary, $amount: 20%);
          }
        }

        .right {
          display: grid;
          background-color: white;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 0px;
          grid-row-gap: 0px;
          align-items: center;

          .item {
            position: relative;
            width: 100%;
            height: 100%;
            border: 1px solid #e9f2fb;
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            &:hover {
              box-shadow: $hy-shadow-container;
              .contact {
                opacity: 1;
              }
            }

            > img {
              width: 150px;
              height: 150px;
            }

            .name {
              margin-top: 10px;
              font-size: $hy-font-size-normal;
            }

            .contact {
              position: absolute;
              opacity: 0;
              bottom: 0;
              width: 100%;
              height: 40px;
              background-color: $hy-color-primary;
              font-size: $hy-font-size-small;
              color: white;
              line-height: 40px;
              text-align: center;
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }
    }
  }
}
