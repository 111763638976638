@import "./hy-theme.scss";

// 背景图
@mixin hy-bg-img($url) {
  background-image: url($url);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

// 通用边框
@mixin hy-border {
  border: 1px solid $hy-color-line;
}

/*
  圆形
*/
@mixin hy-circle($size) {
  width: $size;
  height: $size;
  border-radius: calc($size / 2);
}

/*
  通用面板容器 (圆角、白底，边框)
*/
@mixin hy-panel-border {
  border-radius: $hy-container-radius;
  background-color: white;
  @include hy-border;
}

/*
  通用面板容器 (圆角、白底，阴影)
*/
@mixin hy-panel-shadow {
  border-radius: $hy-radius-container;
  background-color: white;
  box-shadow: $hy-shadow-container;
}

/*
  隐藏滚动条
*/
@mixin hy-hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

/*
  多行文本省略号
*/
@mixin hy-line-clamp($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}