@import "common/styles/preload.scss";
.section {
  padding-bottom: 80px;
  background-color: white;

  .content {
    margin: 0 auto;
    width: $hy-content-width;

    .container {
      margin-top: 35px;

      .topMenus {

        margin-bottom: 40px;

        .menu {

          margin-right: 6px;
          width: 426px;
          height: 80px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: #E9F2FB;
          cursor: pointer;

          > img {
            width: 95px;
            height: 70px;
          }

          .menuTitle {
            font-size: $hy-font-size-normal;
            font-weight: bold;
          }

          .menuDesc {
            margin-top: 10px;
            font-size: $hy-font-size-mini;
          }
        }

        .menuNormal {

          &:hover {
            background-color: lighten($hy-color-primary, 30%);
          }

          .menuTitle {
            color: $hy-color-text-1;
          }
          .menuDesc{
            color: $hy-color-text-3;
          }
        }

        .menuSelect {
          .menuTitle {
            color: white;
          }
          .menuDesc{
            color: white;
          }
        }
      }

      .leftBg {
        margin-right: 34px;
        width: 424px;
        height: 500px;
        border-radius: $hy-radius-container;
      }

      .article {
        height: 106px;
        border-bottom: 1px solid $hy-color-line;
        cursor: pointer;

        .articleTitle {
          font-size: $hy-font-size-title3;
          color: $hy-color-text-1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .articleDesc {
          margin-top: 10px;
          font-size: $hy-font-size-normal;
          color: $hy-color-text-2;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .article:hover {
        .articleTitle {
          color: $hy-color-primary;
        }
        .articleDesc {
          color: $hy-color-primary;
        }
      }

      .button {
        width: 156px;
        height: 50px;
        border: 1px solid $hy-color-primary;
        border-radius: $hy-radius-button;
        font-size: $hy-font-size-normal;
        color: $hy-color-primary;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
      }
    }
  }
}