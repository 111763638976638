@import "common/styles/preload.scss";
.header {
  .content {
    margin: 0 auto;
    width: $hy-content-width;
  }

  .top {
    height: 38px;

    .text {
      font-size: $hy-font-size-small;
    }

    .phoneIcon {
      width: 18px;
      height: 18px;
    }

    .phoneNumber {
      font-size: $hy-font-size-normal;
      font-weight: bold;
    }

    .itemList {
      .item {
        @extend .text;
        padding: 0 12px;
        cursor: pointer;
      }

      span.click {
        margin-left: 10px;
        display: inline-block;
        color: inherit;
        cursor: pointer;
      }
    }
  }

  .navigation {
    height: 70px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    .logo {
      width: 130px;
      height: 42px;
      cursor: pointer;
    }

    .itemList {
      .item {
        position: relative;
        padding: 0 24px;
        font-size: $hy-font-size-normal;

        a {
          color: inherit;
        }

        .hot {
          position: absolute;
          top: -22px;
          width: 30px;
          height: 20px;
          @include hy-bg-img("/common/page-header/hot.png");
          animation: breath 1.6s alternate ease-in-out infinite;
        }

        .new {
          position: absolute;
          top: -22px;
          width: 30px;
          height: 20px;
          @include hy-bg-img("/common/page-header/new.png");
          animation: breath 1.6s alternate ease-in-out infinite 0.6s;
        }

        .arrow {
          width: 20px;
          height: 20px;
        }

        @keyframes breath {
          from {
            transform: translateY(0);
          }
          to {
            transform: translateY(-4px);
          }
        }
      }
    }

    .searchBar {
      padding-left: 20px;
      padding-right: 14px;
      height: 36px;
      border-radius: 18px;
      background-color: rgba(255, 255, 255, 0.3);

      .searchTitle {
        font-size: $hy-font-size-small;
      }

      input {
        border: none;
        outline: none;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 16px;
        width: 140px;
        font-size: $hy-font-size-small;
        background-color: transparent;
      }

      input::placeholder {
        font-size: $hy-font-size-small;
      }

      .searchIcon {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }
}

.white {

  .top {
    background-color: rgba(255, 255, 255, 0.3);

    .text {
      color: white;
    }
    
    .phoneIcon {
      @include hy-bg-img("/common/page-header/phone-white.png");
    }

    .phoneNumber {
      color: white;
    }

    .itemList {

      .item {
        color: white;
      }

      .item:not(:first-of-type) {
        border-left: 1px solid white;
      }

      .item:hover {
        text-shadow: 0 0 6px white;
      }
    }
  }
  .navigation {

    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    .logo {
      @include hy-bg-img("/common/page-header/logo-white.png");
    }
    .itemList {
      .item {
        color: white;
      }
      .item:not(:first-of-type) {
        border-left: 1px solid rgba(255, 255, 255, 0.2);
      }

      .item:hover {
        text-shadow: 0 0 6px white;
      }
    }
    .searchBar {
      .searchTitle {
        color: white;
      }
      input {
        border-left: 1px solid rgba(255, 255, 255, 0.4);
        color: white;
      }
      input::placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
      .searchIcon {
        @include hy-bg-img("/common/page-header/search-white.png");
      }
    }
  }
}

.black {

  background-color: white;

  .top {

    border-bottom: 1px solid #F1F1F1;

    .text {
      color: $hy-color-text-1;
    }
    
    .phoneIcon {
      @include hy-bg-img("/common/page-header/phone-blue.png");
    }

    .phoneNumber {
      color: $hy-color-text-1;
    }

    .itemList {

      .item {
        color: $hy-color-text-1;
      }

      .login {
        color: $hy-color-primary;
      }

      .item:not(:first-of-type) {
        border-left: 1px solid $hy-color-text-3;
      }

      .item:hover {
        color: $hy-color-primary;
      }
    }
  }
  .navigation {

    border-bottom: 1px solid #F1F1F1;

    .logo {
      @include hy-bg-img("/common/page-header/logo-blue.png");
    }
    .itemList {
      .item {
        color: $hy-color-text-1;
      }
      .item:hover {
        color: $hy-color-primary;
      }
    }
    .searchBar {

      background-color: #EFEFEF;

      .searchTitle {
        color: $hy-color-text-1;
      }
      input {
        border-left: 1px solid #cccccc;
        color: $hy-color-text-1;
      }
      input::placeholder {
        color: $hy-color-text-3;
      }
      .searchIcon {
        @include hy-bg-img("/common/page-header/search-blue.png");
      }
    }
  }
}
