@import "common/styles/preload.scss";
.header {
  width: 100%;
  height: 1040px;
  overflow: hidden;
  background-color: #f4f9ff;

  :global {
    .ant-carousel {
      max-width: 100%;
      .slick-dots-bottom {
        bottom: 180px;
      }
    }
  }

  .carousel {
    margin: 0 auto;
    width: 1920px;
    height: 688px;
    left: 50%;
    transform: translateX(-960px);

    .banner {
      position: relative;
      width: 1920px;
      height: 688px;
      cursor: pointer;

      > img {
        position: absolute;
        width: 1920px;
        height: 688px;
      }

      .content {
        position: relative;
        margin: 0 auto;
        width: $hy-content-width;
        margin-top: 184px;
        z-index: $hy-z-low;

        .title {
          font-size: $hy-font-size-banner-title;
          line-height: $hy-font-size-banner-title;
          color: white;
          text-shadow: 0px 0px 7px #134395;
        }

        .descList {
          margin-top: 24px;
          .desc {
            padding-right: 20px;
            font-size: $hy-font-size-title3;
            line-height: $hy-font-size-title3;
            color: white;
            text-shadow: 0px 0px 7px #134395;
          }

          .desc:not(:first-of-type) {
            padding-left: 20px;
            border-left: 1px solid white;
          }
        }

        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 76px;
          border-radius: $hy-radius-button;
          border: 1px solid white;
          width: 120px;
          height: 40px;
          font-size: $hy-font-size-normal;
          color: white;
          cursor: pointer;

          span {
            display: inline-block;
            margin-left: 10px;
            font-size: $hy-font-size-normal;
            font-weight: 200;
            color: white;
          }
        }
      }
    }
  }

  .panel {
    position: relative;
    top: -166px;
    margin: 0 auto;
    width: $hy-content-width;
    box-shadow: $hy-shadow-container;
    border-radius: $hy-radius-container;
    background-color: white;
    animation: appear 1.2s ease-in-out forwards;

    .function {
      width: 100%;
      padding: 17px;

      .business {

        flex-shrink: 0;
        flex-basis: 0;

        .titleBg {
          padding: 0 22px;
          width: 394px;
          height: 70px;
          border-radius: 6px;
          overflow: hidden;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;

          .title {
            font-size: $hy-font-size-normal;
            font-weight: bold;
          }

          .titleEng {
            margin-top: 6px;
            font-size: $hy-font-size-mini;
            color: $hy-color-text-3;
            text-transform: uppercase;
          }
        }

        .businessItems {
          .item {
            margin-top: 18px;
            width: 120px;
            height: 120px;
            cursor: pointer;

            &:hover {
              border-radius: $hy-radius-button;
              box-shadow: $hy-shadow-button;
            }

            img {
              width: 62px;
              height: 62px;
            }

            .name {
              margin-top: 12px;
              font-size: $hy-font-size-normal;
            }
          }
        }
      }

      .line {
        flex-shrink: 0;
        width: 1px;
        height: auto;
        background-color: #e1e4fa;
      }
    }

    .bottom {
      padding-left: 28px;
      padding-right: 40px;
      height: 70px;
      background-color: #f4f9ff;

      img {
        margin-right: 20px;
        width: 44px;
        height: 44px;
      }

      .text1 {
        font-size: $hy-font-size-small;
      }

      .text2 {
        margin-top: 8px;
        font-size: $hy-font-size-mini;
        color: $hy-color-text-3;
      }

      .line {
        width: 1px;
        height: 40px;
        background-color: #CFDEF4;
      }
    }
  }

  @keyframes appear {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
