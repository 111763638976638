@import "common/styles/preload.scss";

.section {
  padding-bottom: 80px;
  background-color: white;

  .content {
    margin: 0 auto;
    width: $hy-content-width;

    .container {
      margin-top: 60px;

      .card {
        padding: 20px 10px;
        width: 418px;
        height: 586px;
        border-radius: $hy-radius-container;
        box-shadow: $hy-shadow-container;

        .titleIcon {
          width: 220px;
          height: 220px;
          object-fit: contain;
        }

        .title {
          margin-top: 6px;
          font-size: $hy-font-size-title3;
          font-weight: bold;
        }

        .desc {
          margin-top: 10px;
          font-size: $hy-font-size-normal;
          color: $hy-color-text-3;
          text-align: center;
          line-height: 25px;
          white-space: pre-line;
        }

        .item {
          background-color: #E9F2FB;
          width: 100%;
          height: 75px;

          &Icon {
            margin-left: 28px;
            margin-right: 20px;
            width: 30px;
            height: 30px;
          }

          &Title {
            font-size: $hy-font-size-normal;
          }

          &Desc {
            margin-top: 10px;
            font-size: $hy-font-size-small;
            color: $hy-color-text-3;
          }
        }

        .itemLine {
          width: 360px;
          height: 1px;
          background-color: rgba(186, 194, 202, 0.6);
        }

        .buttons {

          margin-top: 10px;
          width: 100%;

          .button {
            opacity: 0;
            height: 0;
            border-radius: $hy-radius-button;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:nth-of-type(1) {
              margin-right: 10px;
              background-color: #3DBFDC;
            }

            &:nth-of-type(2) {
              background-color: $hy-color-primary;
            }
  
            >img {
              margin-right: 10px;
              width: 15px;
              height: 15px;
            }
  
            &Text {
              font-size: $hy-font-size-small;
              color: white;
            }
          }
        }
      }

      .card:hover {
        .button {
          opacity: 1;
          height: 40px;
        }
      }
    }
  }
}