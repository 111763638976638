@import "common/styles/preload.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 108px;

  &.white {
    border-bottom: 1px solid rgba(221, 234, 248, 0.2);
    .title {
      color: white;
      border-bottom-color: white;
    }
    .words {
      color: #DADFEF;
    }
    .line {
      background-color: white;
    }
  }

  &.black {
    border-bottom: 1px solid #dae7fd;
    .title {
      border-bottom-color: $hy-color-primary;
    }
    .words {
      color: $hy-color-text-3;
    }
  }

  .content {
    margin: 0 auto;
    width: $hy-content-width;
  }

  .title {
    margin-right: 20px;
    padding-bottom: 12px;
    font-size: $hy-font-size-title1;
    border-bottom-width: 5px;
    border-bottom-style: solid;
  }

  .words {
    font-size: $hy-font-size-small;
  }
}
