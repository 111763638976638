@import "common/styles/preload.scss";

.container {
  padding: 2px;
  border-radius: $hy-radius-container;
  background-color: white;

  .menu {
    font-size: $hy-font-size-small;
    color: $hy-color-text-1;
    cursor: pointer;

    &:hover {
      color: $hy-color-primary;
    }
    &:not(:last-of-type) {
      margin-bottom: 6px;
    }
  }
}